// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-control
{
    width: 100%;
    margin-left: 1em;
    margin-right: 1em;
    justify-content: center;
}

.form-card
{
    margin-bottom: 1em;
    padding-right: 4em;
}

.btn-submit
{
    width: 100%;
    margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/css/directorship-meeting.css"],"names":[],"mappings":"AAAA;;IAEI,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;;IAEI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;;IAEI,WAAW;IACX,iBAAiB;AACrB","sourcesContent":[".form-control\n{\n    width: 100%;\n    margin-left: 1em;\n    margin-right: 1em;\n    justify-content: center;\n}\n\n.form-card\n{\n    margin-bottom: 1em;\n    padding-right: 4em;\n}\n\n.btn-submit\n{\n    width: 100%;\n    margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
