// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-info
{
    display: flex;

}

.add-user
{
    margin-top: 2rem;
}

.flexbox
{
    display: flex;
}

.input-user-info input[type='checkbox']
{
    display: inline;
}

.btn-create-user
{
    width: 100%;
    margin-top: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/css/member-management.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;;AAEjB;;AAEA;;IAEI,gBAAgB;AACpB;;AAEA;;IAEI,aAAa;AACjB;;AAEA;;IAEI,eAAe;AACnB;;AAEA;;IAEI,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".admin-info\n{\n    display: flex;\n\n}\n\n.add-user\n{\n    margin-top: 2rem;\n}\n\n.flexbox\n{\n    display: flex;\n}\n\n.input-user-info input[type='checkbox']\n{\n    display: inline;\n}\n\n.btn-create-user\n{\n    width: 100%;\n    margin-top: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
