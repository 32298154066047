// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dtp div.dtp-date,
.dtp div.dtp-time
{
    background: #007bff;
    /* Assuming \$primary-color is #007bff */
}

.dtp div.dtp-actual-year
{
    color: #b3c7ff;
    /* Assuming \$primary-color is #007bff */
}

.dtp .p10>a
{
    color: #b3c7ff;
    /* Assuming \$primary-color is #007bff */
}

.dtp table.dtp-picker-days tr td a.selected
{
    background: #007bff;
    /* Assuming \$primary-color is #007bff */
}

.dtp .dtp-content .dtp-date-view header.dtp-header
{
    background: #0069d9;
    /* Darken \$primary-color by 5% (assuming \$primary-color is #007bff) */
}

.dtp .dtp-buttons
{
    background: #fff;
}`, "",{"version":3,"sources":["webpack://./src/css/util/datepicker.css"],"names":[],"mappings":"AAAA;;;IAGI,mBAAmB;IACnB,uCAAuC;AAC3C;;AAEA;;IAEI,cAAc;IACd,uCAAuC;AAC3C;;AAEA;;IAEI,cAAc;IACd,uCAAuC;AAC3C;;AAEA;;IAEI,mBAAmB;IACnB,uCAAuC;AAC3C;;AAEA;;IAEI,mBAAmB;IACnB,qEAAqE;AACzE;;AAEA;;IAEI,gBAAgB;AACpB","sourcesContent":[".dtp div.dtp-date,\n.dtp div.dtp-time\n{\n    background: #007bff;\n    /* Assuming $primary-color is #007bff */\n}\n\n.dtp div.dtp-actual-year\n{\n    color: #b3c7ff;\n    /* Assuming $primary-color is #007bff */\n}\n\n.dtp .p10>a\n{\n    color: #b3c7ff;\n    /* Assuming $primary-color is #007bff */\n}\n\n.dtp table.dtp-picker-days tr td a.selected\n{\n    background: #007bff;\n    /* Assuming $primary-color is #007bff */\n}\n\n.dtp .dtp-content .dtp-date-view header.dtp-header\n{\n    background: #0069d9;\n    /* Darken $primary-color by 5% (assuming $primary-color is #007bff) */\n}\n\n.dtp .dtp-buttons\n{\n    background: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
