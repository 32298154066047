// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body
{
  font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 1.846;
  color: #666;
  background-color: #eee;
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code
{
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}`, "",{"version":3,"sources":["webpack://./src/index.tsx.css"],"names":[],"mappings":"AAAA;;EAEE,iEAAiE;EACjE,eAAe;EACf,kBAAkB;EAClB,WAAW;EACX,sBAAsB;EACtB,SAAS;EACT,2EAA2E;EAC3E,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;;EAEE;aACW;AACb","sourcesContent":["body\n{\n  font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;\n  font-size: 13px;\n  line-height: 1.846;\n  color: #666;\n  background-color: #eee;\n  margin: 0;\n  font-family: \"Roboto\", \"Helvetica Neue\", \"Helvetica\", \"Arial\", \"sans-serif\";\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode\n{\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
