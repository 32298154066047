// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-container {
  display: flex;
}

.spinner-container.centered {
  justify-content: center;
}

.info-spinner,
.info-spinner:after {
  width: 2em;
  height: 2em;
}

.info-spinner {
  border-width: 0.4em;
}

.spinner-text {
  margin-left: 6px;
  line-height: 1.6em;
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/InfoSpinner.tsx.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI,uBAAA;AACJ;;AAEA;;EAEI,UAAA;EACA,WAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,kBAAA;AACJ;AAcA;EACI;IAEI,uBAAA;EADN;EAIE;IAEI,yBAAA;EAFN;AACF","sourcesContent":[".spinner-container {\n    display: flex;\n}\n\n.spinner-container.centered {\n    justify-content: center;\n}\n\n.info-spinner,\n.info-spinner:after {\n    width: 2em;\n    height: 2em;\n}\n\n.info-spinner {\n    border-width: 0.4em;\n}\n\n.spinner-text {\n    margin-left: 6px;\n    line-height: 1.6em;\n}\n\n@-webkit-keyframes load8 {\n    0% {\n        -webkit-transform: rotate(0deg);\n        transform: rotate(0deg);\n    }\n\n    100% {\n        -webkit-transform: rotate(360deg);\n        transform: rotate(360deg);\n    }\n}\n\n@keyframes load8 {\n    0% {\n        -webkit-transform: rotate(0deg);\n        transform: rotate(0deg);\n    }\n\n    100% {\n        -webkit-transform: rotate(360deg);\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
