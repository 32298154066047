// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header
{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

#coop-form
{
    background-color: #ffffff;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
    padding-bottom: 10px;
}

.info-text
{
    font-size: 11pt;
    margin-top: 1em;
}

.semester-selection
{
    /* text-align: center; */
    display: flex;
    justify-content: space-between;
    margin-left: 10em;
    margin-right: 10em;
}

.coop-radio
{
    font-size: 30pt;
}

.submit-btn
{
    display: flex;
    background-color: #b0197e;
    width: 100%;
    justify-content: center;
    color: white;
}

.submit-btn:hover
{
    background-color: #95156b;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/css/coop-submission.css"],"names":[],"mappings":"AAAA;;IAEI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;;IAEI,yBAAyB;IACzB,2CAA2C;IAC3C,oBAAoB;AACxB;;AAEA;;IAEI,eAAe;IACf,eAAe;AACnB;;AAEA;;IAEI,wBAAwB;IACxB,aAAa;IACb,8BAA8B;IAC9B,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;;IAEI,eAAe;AACnB;;AAEA;;IAEI,aAAa;IACb,yBAAyB;IACzB,WAAW;IACX,uBAAuB;IACvB,YAAY;AAChB;;AAEA;;IAEI,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":[".header\n{\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n}\n\n#coop-form\n{\n    background-color: #ffffff;\n    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);\n    padding-bottom: 10px;\n}\n\n.info-text\n{\n    font-size: 11pt;\n    margin-top: 1em;\n}\n\n.semester-selection\n{\n    /* text-align: center; */\n    display: flex;\n    justify-content: space-between;\n    margin-left: 10em;\n    margin-right: 10em;\n}\n\n.coop-radio\n{\n    font-size: 30pt;\n}\n\n.submit-btn\n{\n    display: flex;\n    background-color: #b0197e;\n    width: 100%;\n    justify-content: center;\n    color: white;\n}\n\n.submit-btn:hover\n{\n    background-color: #95156b;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
