// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html
{
    height: 100%;
    display: flex;
    margin: 0;
}

html>*
{
    height: 100%;
    width: 100%;
}

.housing-container
{
    display: flex;
    justify-content: space-between;
}

.odd
{
    background-color: #ffffff;
}

.even
{
    background-color: #f9f9f9;
}

.panel
{
    width: 48%;
    display: flex;
    flex-direction: column;
}

.main
{
    justify-content: center;
    display: flex;
}

.panel-title
{
    margin: 0;
    padding-left: 10px;
}

.table
{
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/css/housing.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;IACZ,aAAa;IACb,SAAS;AACb;;AAEA;;IAEI,YAAY;IACZ,WAAW;AACf;;AAEA;;IAEI,aAAa;IACb,8BAA8B;AAClC;;AAEA;;IAEI,yBAAyB;AAC7B;;AAEA;;IAEI,yBAAyB;AAC7B;;AAEA;;IAEI,UAAU;IACV,aAAa;IACb,sBAAsB;AAC1B;;AAEA;;IAEI,uBAAuB;IACvB,aAAa;AACjB;;AAEA;;IAEI,SAAS;IACT,kBAAkB;AACtB;;AAEA;;IAEI,SAAS;AACb","sourcesContent":["html\n{\n    height: 100%;\n    display: flex;\n    margin: 0;\n}\n\nhtml>*\n{\n    height: 100%;\n    width: 100%;\n}\n\n.housing-container\n{\n    display: flex;\n    justify-content: space-between;\n}\n\n.odd\n{\n    background-color: #ffffff;\n}\n\n.even\n{\n    background-color: #f9f9f9;\n}\n\n.panel\n{\n    width: 48%;\n    display: flex;\n    flex-direction: column;\n}\n\n.main\n{\n    justify-content: center;\n    display: flex;\n}\n\n.panel-title\n{\n    margin: 0;\n    padding-left: 10px;\n}\n\n.table\n{\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
