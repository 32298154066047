// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div .dataTables_wrapper .dataTable
{
    margin-top: 0 !important;
}

div .dataTables_wrapper .dataTables_paginate
{
    margin: 0 0 10px !important;
    text-align: center !important;
}

@media (max-width: 768px)
{
    div .dataTables_wrapper .dataTables_paginate
    {
        margin-right: 0 !important;
    }
}

div .dataTables_wrapper .dataTables_length
{
    margin-top: 5px;
    margin-left: 15px;
}

div .dataTables_wrapper .dataTables_filter
{
    margin-top: 5px;
    margin-right: 15px;
}

div .dataTables_wrapper .col-sm-7
{
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/css/util/datatable.css"],"names":[],"mappings":"AAAA;;IAEI,wBAAwB;AAC5B;;AAEA;;IAEI,2BAA2B;IAC3B,6BAA6B;AACjC;;AAEA;;IAEI;;QAEI,0BAA0B;IAC9B;AACJ;;AAEA;;IAEI,eAAe;IACf,iBAAiB;AACrB;;AAEA;;IAEI,eAAe;IACf,kBAAkB;AACtB;;AAEA;;IAEI,WAAW;AACf","sourcesContent":["div .dataTables_wrapper .dataTable\n{\n    margin-top: 0 !important;\n}\n\ndiv .dataTables_wrapper .dataTables_paginate\n{\n    margin: 0 0 10px !important;\n    text-align: center !important;\n}\n\n@media (max-width: 768px)\n{\n    div .dataTables_wrapper .dataTables_paginate\n    {\n        margin-right: 0 !important;\n    }\n}\n\ndiv .dataTables_wrapper .dataTables_length\n{\n    margin-top: 5px;\n    margin-left: 15px;\n}\n\ndiv .dataTables_wrapper .dataTables_filter\n{\n    margin-top: 5px;\n    margin-right: 15px;\n}\n\ndiv .dataTables_wrapper .col-sm-7\n{\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
