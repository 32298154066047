// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-flat
{
    position: relative;
    margin: 0 5px;
    outline: 0 !important;
    border: 0;
    box-shadow: none !important;
    background-color: transparent;
    padding: 8px 25px;
    text-transform: uppercase;
    text-decoration: none;
}

.btn-flat:hover
{
    background-color: rgba(153, 153, 153, .2);
}

.btn-flat:active
{
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .4);
    background: radial-gradient(circle, #7a1158 10%, #b0197e 11%) rgba(153, 153, 153, .2);
    background-size: 1000% 1000%;
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/css/util/flat-button.css"],"names":[],"mappings":"AAAA;;IAEI,kBAAkB;IAClB,aAAa;IACb,qBAAqB;IACrB,SAAS;IACT,2BAA2B;IAC3B,6BAA6B;IAC7B,iBAAiB;IACjB,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;;IAEI,yCAAyC;AAC7C;;AAEA;;IAEI,yCAAyC;IACzC,qFAAqF;IACrF,4BAA4B;IAC5B,WAAW;AACf","sourcesContent":[".btn-flat\n{\n    position: relative;\n    margin: 0 5px;\n    outline: 0 !important;\n    border: 0;\n    box-shadow: none !important;\n    background-color: transparent;\n    padding: 8px 25px;\n    text-transform: uppercase;\n    text-decoration: none;\n}\n\n.btn-flat:hover\n{\n    background-color: rgba(153, 153, 153, .2);\n}\n\n.btn-flat:active\n{\n    box-shadow: 2px 2px 4px rgba(0, 0, 0, .4);\n    background: radial-gradient(circle, #7a1158 10%, #b0197e 11%) rgba(153, 153, 153, .2);\n    background-size: 1000% 1000%;\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
