// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-title
{
    font-size: 18pt;
    margin-top: 1em;
    margin-bottom: 1em;
}

.input-label
{
    font-size: 12pt;
    margin: 1em;
}

.form-card
{
    margin: 1em;
}

.btn-submit
{
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/css/intro-evals-form.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;IACf,eAAe;IACf,kBAAkB;AACtB;;AAEA;;IAEI,eAAe;IACf,WAAW;AACf;;AAEA;;IAEI,WAAW;AACf;;AAEA;;IAEI,WAAW;AACf","sourcesContent":[".page-title\n{\n    font-size: 18pt;\n    margin-top: 1em;\n    margin-bottom: 1em;\n}\n\n.input-label\n{\n    font-size: 12pt;\n    margin: 1em;\n}\n\n.form-card\n{\n    margin: 1em;\n}\n\n.btn-submit\n{\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
