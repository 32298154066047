// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.member
{
    background-color: #ffffff;
    padding: 25px 20px;
    display: flex;
    margin: 20px;
    box-shadow: 4px 4px 1px rgba(0, 0, 0, 0.25);
}

.profilepicture
{
    display: flex;
    width: 100px;
    height: 100px;
    align-items: center;
}

.name-id
{
    margin-left: 30px;
    height: 100%;
    align-items: center;
    width: max-content;
}

.evals-status
{
    width: 100%;
    text-align: right;
    margin-right: 20px;
}

.spring-req
{
    font-size: 10pt;
    font-weight: 500;
}

.username
{
    font-size: 12pt;
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/css/spring-evals.css"],"names":[],"mappings":"AAAA;;IAEI,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,2CAA2C;AAC/C;;AAEA;;IAEI,aAAa;IACb,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;;IAEI,iBAAiB;IACjB,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;;IAEI,WAAW;IACX,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;;IAEI,eAAe;IACf,gBAAgB;AACpB;;AAEA;;IAEI,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".member\n{\n    background-color: #ffffff;\n    padding: 25px 20px;\n    display: flex;\n    margin: 20px;\n    box-shadow: 4px 4px 1px rgba(0, 0, 0, 0.25);\n}\n\n.profilepicture\n{\n    display: flex;\n    width: 100px;\n    height: 100px;\n    align-items: center;\n}\n\n.name-id\n{\n    margin-left: 30px;\n    height: 100%;\n    align-items: center;\n    width: max-content;\n}\n\n.evals-status\n{\n    width: 100%;\n    text-align: right;\n    margin-right: 20px;\n}\n\n.spring-req\n{\n    font-size: 10pt;\n    font-weight: 500;\n}\n\n.username\n{\n    font-size: 12pt;\n    font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
