// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-slider
{
    position: relative;
    width: 60px;
    height: 34px;
}

.slider
{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    cursor: pointer;
    transition: 0.4s;
}

.slider:before
{
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
}

input[type='checkbox']
{
    display: none;
}

input[type='checkbox']:checked+.slider
{
    background-color: #2196f3;
}

input[type='checkbox']:checked+.slider:before
{
    transform: translateX(26px);
}`, "",{"version":3,"sources":["webpack://./src/css/toggle-slider.css"],"names":[],"mappings":"AAAA;;IAEI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;;IAEI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB;;AAEA;;IAEI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,SAAS;IACT,WAAW;IACX,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;;IAEI,aAAa;AACjB;;AAEA;;IAEI,yBAAyB;AAC7B;;AAEA;;IAEI,2BAA2B;AAC/B","sourcesContent":[".toggle-slider\n{\n    position: relative;\n    width: 60px;\n    height: 34px;\n}\n\n.slider\n{\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: #ccc;\n    border-radius: 34px;\n    cursor: pointer;\n    transition: 0.4s;\n}\n\n.slider:before\n{\n    position: absolute;\n    content: '';\n    height: 22px;\n    width: 22px;\n    left: 4px;\n    bottom: 2px;\n    background-color: white;\n    border-radius: 50%;\n    transition: 0.4s;\n}\n\ninput[type='checkbox']\n{\n    display: none;\n}\n\ninput[type='checkbox']:checked+.slider\n{\n    background-color: #2196f3;\n}\n\ninput[type='checkbox']:checked+.slider:before\n{\n    transform: translateX(26px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
