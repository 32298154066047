// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-title
{
  margin-bottom: 1em;
  margin-left: 15px;
  margin-top: 10px;
}

.section-header
{
  margin-top: 30px;
  font-size: 18pt;
  margin-left: 10px;
}

.input-label
{
  font-size: 12pt;
  font-weight: 400;
  margin-left: 1em;
  margin-top: 3px;
}

.form-card
{
  margin: 1em;
  width: 100%;
  padding: 1em;
  margin-bottom: 1em;
}

.btn-submit
{
  width: 100%;
  margin-left: 10px;
}

.large-text
{
  font-size: 14pt;
  margin-top: 50px;
}`, "",{"version":3,"sources":["webpack://./src/css/major-project-form.css"],"names":[],"mappings":"AAAA;;EAEE,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;;EAEE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;;EAEE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;;EAEE,WAAW;EACX,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;;EAEE,WAAW;EACX,iBAAiB;AACnB;;AAEA;;EAEE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".page-title\n{\n  margin-bottom: 1em;\n  margin-left: 15px;\n  margin-top: 10px;\n}\n\n.section-header\n{\n  margin-top: 30px;\n  font-size: 18pt;\n  margin-left: 10px;\n}\n\n.input-label\n{\n  font-size: 12pt;\n  font-weight: 400;\n  margin-left: 1em;\n  margin-top: 3px;\n}\n\n.form-card\n{\n  margin: 1em;\n  width: 100%;\n  padding: 1em;\n  margin-bottom: 1em;\n}\n\n.btn-submit\n{\n  width: 100%;\n  margin-left: 10px;\n}\n\n.large-text\n{\n  font-size: 14pt;\n  margin-top: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
